/* Editable */


#loader{
	position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 999999;
    overflow: visible;
    background: var(--rgba-primary-1) url(../images/loader.gif) no-repeat center center;
	@include respond ('tab-port') {
		background: var(--rgba-primary-1) url(../images/loader-small.gif) no-repeat center center;		
	}
}

[data-theme-version="dark"] {
	#loader{
		background-color: $d-bg;
	}
}